import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Grid, TextField, Card } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import env from 'config';

const AgencySetting = () => {
  // State variables to manage form inputs
  const [instructions, setInstructions] = useState('');
  const [purpose, setPurpose] = useState('');
  const [creativity, setCreativity] = useState('');
  const [balanceText, setBalanceText] = useState('');
  const [tags, setTags] = useState('');
  const [agencyOnly, setAgencyOnly] = useState('');
  
  const BASE_URL = `${env.API_URL}/v1`;

  useEffect(() => {
    axios.get(BASE_URL + '/super_admin_settings/')
      .then(response => {
        const data = response.data.data;

        setInstructions(data.assistant_instructions);
        setPurpose(data.assistant_purpose);
        setCreativity(data.content_creativity);
        setBalanceText(data.balance_text_generation);
        setTags(data.assistant_tags);
        setAgencyOnly(data.assistant_agency_only);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      assistant_instructions: instructions,
      assistant_purpose: purpose,
      content_creativity: creativity,
      balance_text_generation: balanceText,
      assistant_tags: tags,
      assistant_agency_only: agencyOnly
    };

    axios.put(BASE_URL + '/super_admin_settings/', payload)
      .then(response => {
        console.log('Data updated successfully:', response.data);
      })
      .catch(error => {
        console.error('Error updating data:', error);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Agency Setting
                </MDTypography>
              </MDBox>
              <MDBox py={5}>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3} px={3}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Assistant Instructions"
                        variant="outlined"
                        value={instructions}
                        onChange={(e) => setInstructions(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Assistant Purpose"
                        variant="outlined"
                        value={purpose}
                        onChange={(e) => setPurpose(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Content Creativity"
                        variant="outlined"
                        value={creativity}
                        onChange={(e) => setCreativity(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Balance Text Generation"
                        variant="outlined"
                        value={balanceText}
                        onChange={(e) => setBalanceText(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Assistant Tags"
                        variant="outlined"
                        value={tags}
                        onChange={(e) => setTags(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Agency Only"
                        variant="outlined"
                        value={agencyOnly}
                        onChange={(e) => setAgencyOnly(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{ color: "white" }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default AgencySetting;
