// @mui material components
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'

// @mui icons
// import FacebookIcon from '@mui/icons-material/Facebook'
// import TwitterIcon from '@mui/icons-material/Twitter'
// import InstagramIcon from '@mui/icons-material/Instagram'
// Material Dashboard 2 React components

import MDBox from 'components/MDBox'
import localforage from 'localforage'
// Material Dashboard 2 React example components
import ProfileInfoCard from 'examples/Cards/InfoCards/ProfileInfoCard'
// import ProfilesList from 'examples/Lists/ProfilesList'

import { useUserInfo } from 'context/user'
import burceMars from 'assets/images/bruce-mars.jpg'

// Overview page components

import MDAvatar from 'components/MDAvatar'
import MDTypography from 'components/MDTypography'
import { Alert, Icon, Stack } from '@mui/material'
import { useLogout } from 'hook/auth'
import { useEffect, useState } from 'react'
import env from 'config'
import MDButton from 'components/MDButton'
import axios from 'axios';

function DefaultPrompt() {
  const [supperadminsetting, setSupperadminsetting] = useState({})
  const [processing, setProcessing] = useState(false)
  const [errormsg, seterrormsg] = useState('');
  const [response, setresponse] = useState('')

  const [user] = useUserInfo()
  const Logout = useLogout()
  const BASE_URL = `${env.API_URL}/v1`

  useEffect(async () => {



    axios.get(BASE_URL + '/super_admin_settings/').then(async function (response) {
      setSupperadminsetting(response.data.data)
    })
      .catch(function (error) {
        console.log(error);
      });
  }, [])
   const handlesubmit = (e) => {
    e.preventDefault();
    setProcessing(true)

    var data = {
      default_assistant: {
       assistant_name: e.target.assistant_name.value,
       assistant_description: e.target.assistant_description.value,
       assistant_temprature: 1,
       assistant_top_p:1,
        model: "openai/gpt-4o",
        tags: ["HR", "recruitment"],
        profile_id: "supper_admin",
        agency_id: "65fbced4ee8af4d080cd0de2",
        tools: ["file_search"],
        read_only:true,
        assistant_id:supperadminsetting?.default_assistant?.assistant_id
      },
      _id: supperadminsetting._id
    }
    axios
      .post(BASE_URL + '/super_admin_settings/default_assistant', data)
      .then(async function (response) {
        setProcessing(false)
        setresponse(response.data.message)
      })
      .catch(function (error) {
        seterrormsg(error.response.data.message)

      })
  }
  return (
    <>
      <MDBox mb={2} />

      <MDBox mt={5} mb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} xl={12}  >
            {errormsg != '' ? (
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="error">{errormsg}</Alert>
              </Stack>
            ) : (
              <></>
            )}
            {response != '' ? (
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="success">{response}</Alert>
              </Stack>
            ) : (
              <></>
            )}
            <form onSubmit={handlesubmit}>
              {supperadminsetting._id &&
                <>
                  <label>Assistant Name                  </label>
                  <input name="assistant_name" className='form-control' defaultValue={supperadminsetting.default_assistant.assistant_name} style={{ marginBottom: '20px' }} />
                  <label>Instructions</label>
                  <input name="assistant_description" className='form-control' defaultValue={supperadminsetting.default_assistant.assistant_description} style={{ marginBottom: '20px' }} />
                  <label>Temprature</label>
                  <input type='range' min={0} max={2} name="assistant_temprature"   defaultValue={supperadminsetting.default_assistant.assistant_temprature} style={{ marginBottom: '20px' }} />
                  <br></br>
                  <label>Frequency</label>
                  <input type='range' min={0} max={10} name="assistant_top_p"  defaultValue={supperadminsetting.default_assistant.assistant_top_p} style={{ marginBottom: '20px' }} />

                </>
              }

              <div style={{ textAlign: "right" }}>
                <MDButton
                  variant="gradient"
                  color="info"
                  type="submit"
                  loading={processing}
                  disabled={processing}
                >
                  Update
                </MDButton>
              </div>
            </form>

          </Grid>
        </Grid>
      </MDBox>
    </>
  )
}

export default DefaultPrompt